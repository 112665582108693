import React, { useState } from "react";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { LiaTimesSolid } from "react-icons/lia";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import useAuthStore from "../../../store/authStore";
import { useMutation, useQuery } from "react-query";
import { fetchCategories } from "../../../utils/fetchCategories";
import useCategoriesStore from "../../../store/categoriesStore";
import AllcategoriesTable from "./AllCategoriesTable";
import AddCategoryForm from "./AddCategoryForm";
import { HiOutlineSearch } from "react-icons/hi";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { toast } from "react-toastify";
import { deleteCategory } from "../../../utils/deleteCategory";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";

function CategoriesHome() {
  const [openModal, setOpenModal] = useState({});
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([])
  const { Categories, saveCategories } = useCategoriesStore();
  const { userProfile, removeUser } = useAuthStore();
  const [searchQuery, setSearchQuery] = useState("");


  const { isError, isLoading: loadingCategories, refetch } = useQuery(
    "fetchCategories",
    fetchCategories,
    {
      enabled: true,
      onSuccess: ({ data }) => {
        saveCategories(data.data);
      },
      onError: ({ response }) => {
        console.log(`${response?.data?.status}`);
        if (response?.data?.status === 401) {
          removeUser();
        }
      },
    }
  );

  const mutation = useMutation(deleteCategory);

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        await mutation.mutateAsync(row?.id);
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk delete operation");
    }
  };
  const activateMutation = useMutation({
    mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
  })
  const handleBulkActivate = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        console.log(row?.id)
        await activateMutation.mutateAsync({ entityName: "Categories", id: row?.id });
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk activate operation");
    }
  };

  return (
    <div className="min-h-[85vh]">
      <div className="flex justify-between items-center mb-[2rem] ">
        <div className="flex items-center gap-4">
          <h1 className="text-[20px] font-semibold">Manage Categories</h1>
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
        <div className="w-fit">
          <Buttons
            type="button"
            text="Add New Category"
            variant="primary"
            handleClick={() => setOpenModal({ modalName: "add", showModal: true })}
          />
        </div>
      </div>
      {selectedRows?.length > 0 &&
        <div className="flex gap-2 items-center">
          <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
          <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
        </div>
      }
      <div className="mt-4 mb-8">
        <AllcategoriesTable Data={Categories} loading={loadingCategories} fetchCategories={refetch} openModal={openModal} setOpenModal={setOpenModal} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
      </div>
      {openModal.modalName === "add" && openModal.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Add New Category</h3>
              <LiaTimesSolid
                onClick={() => setOpenModal(false)}
                className="cursor-pointer"
              />
            </div>
            <AddCategoryForm fetchCategories={refetch} setOpenModal={setOpenModal} />
          </div>
        </ModalContainer>
      )}
      <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these categories?"
        onConfirm={handleBulkDelete}
      // loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "activate" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate these categories?"
        onConfirm={handleBulkActivate}
      // loading={deletingUser}
      />
    </div>
  );
}

export default CategoriesHome;
