import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import useIdeaStore from "../../../store/ideaStore";
import EmptyIdea from "./EmptyIdea";
import IdeasCard from "../../Dashboard/IdeasCard";
import FloatingAdd from "../../../CustomUIElements/FloatingIcons/FloatingIcon";
import CreateIdea from "../CreateIdea/CreateIdea";
import { fetchMyIdeas } from "../../../utils/fetchMyIdeas";
import { useQuery } from "react-query";
import useAuthStore from "../../../store/authStore";

function IdeasPage() {
  const { ideas, saveIdeas } = useIdeaStore();
  const { removeUser } = useAuthStore()
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { isLoading: loadingIdeas } = useQuery("fetch ideas", fetchMyIdeas, {
    onSuccess: ({ data }) => {
      saveIdeas(data);
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser();
      }
    },
  });
  return (
    <div className="app-container mt-[5.2rem]">
      <div className="w-[82rem] mx-auto pb-[1.7rem] mb-[4rem] flex justify-between items-center border-b">
        <p className="text-[3.2rem] font-semibold text-[#103240]">My Ideas</p>
        <AiOutlinePlus size={24} onClick={() => setOpenCreateModal(true)} className="cursor-pointer" />
      </div>
      <div className="w-[82rem] mx-auto">
        {ideas?.data?.length < 1 ? (
          <EmptyIdea />
        ) : (
          ideas?.data?.map(
            ({ id, name, categoryId, dateAdded, progressScore }) => {
              return (
                <IdeasCard
                  key={name}
                  name={name}
                  category={categoryId}
                  dateAdded={dateAdded}
                  id={id}
                  progressScore={progressScore}
                />
              );
            }
          )
        )}
      </div>
      {openCreateModal && (
        <CreateIdea setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} />
      )}
    </div>
  );
}

export default IdeasPage;
