import React, { useEffect, useState } from "react";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { LiaTimesSolid } from "react-icons/lia";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import AllQuestionsTable from "./AllIquestionsTable";
import { fetchFunnels } from "../../../utils/fetchFunnels";
import useAuthStore from "../../../store/authStore";
import { fetchQuestions } from "../../../utils/fetchQuestions";
import { addQuestion } from "../../../utils/addQuestion";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import { useMutation } from "react-query";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { toast } from "react-toastify";
import { fetchQuestionsByFunnelId } from "../../../utils/fetchQuestionsByFunnelId";

function QuestionsHome() {
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedRows, setSelectedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [funnels, setFunnels] = useState([])
  const { removeUser } = useAuthStore()
  const [selectedFunnel, setSelectedFunnel] = useState("")
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    fetchFunnels().then((res) => {
      setFunnels(res?.data?.data);
    }).catch((err) => {
      if (err?.response?.status === 401) {
        removeUser()
        window.localStorage.clear()
        window.location.href = "/login"
      }
    })
  }, [])

  const fetchQuestionsHandler = async () => {
    setLoading(true)
    await fetchQuestionsByFunnelId({ id: selectedFunnel }).then((res) => {
      console.log(res?.data?.data)
      setTableData(res?.data?.data)
      setLoading(false)
    }).catch((err) => {
      if (err?.ressponse?.status === 401) {
        removeUser()
        window.localStorage.clear()
        window.location.href = "/login"
      }
      setLoading(false)
    })
  }
  useEffect(() => {
    if (selectedFunnel !== "") {
      fetchQuestionsHandler()
    }
  }, [selectedFunnel])

  const addQuestionHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    await addQuestion({
      name: formValues?.questionName,
      funnelId: parseInt(selectedFunnel),
      isAnswered: false
    }).then(() => {
      setSelectedFunnel("")
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      if (err?.response?.status === 401) {
        removeUser()
        window.localStorage.clear()
        window.location.href = "/login"
      }
    })
  }

  const mutation = useMutation();

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        await mutation.mutateAsync(row?.id);
      }));
      toast.success("Operation completed successfully");
      // refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk delete operation");
    }
  };
  const activateMutation = useMutation({
    mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
  })
  const handleBulkActivate = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        console.log(row?.id)
        await activateMutation.mutateAsync({ entityName: "Questions", id: row?.id });
      }));
      toast.success("Operation completed successfully");
      // refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk activate operation");
    }
  };

  return (
    <div className="min-h-[98vh] pb-5 px-16 bg-[#f1f1f1]">
      <div className="flex pt-[7rem] justify-between items-center">
        <div className="flex items-center gap-[5rem]">
          <h1 className="text-[20px] font-semibold">
            Manage Questions
          </h1>
          <div className="flex items-center gap-4">
            <label className="block text-[1.5rem] mr-2">Select Funnel</label>
            <select className="border rounded-xl outline-none min-w-[200px] p-2 bg-white text-[1.6rem]" value={selectedFunnel} onChange={(e) => setSelectedFunnel(e.target.value)}>
              <option value="" selected>Select one</option>
              {funnels?.filter((f) => f?.isActive)?.map((funnel) => (
                <option value={funnel?.id}>{funnel?.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-fit">
          <Buttons
            type="button"
            text="Add Question"
            variant="primary"
            handleClick={() => { setOpenModal(true); setSelectedFunnel("") }}
          />
        </div>
      </div>
      {selectedRows?.length > 0 &&
        <div className="flex gap-2 items-center">
          <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
          <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
        </div>
      }
      {selectedFunnel !== "" ?
        <div className="mt-4 mb-8">
          <AllQuestionsTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} data={tableData} loading={loading} funnels={funnels} refetch={fetchQuestionsHandler} />
        </div>
        :
        <div className="mt-[100px] text-center">
          <p className="text-[1.6rem]">Select a funnel to see questions within that funnel</p>
        </div>
      }
      {openModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Add New Question</h3>
              <LiaTimesSolid
                onClick={() => setOpenModal(false)}
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]" onSubmit={addQuestionHandler}>
              <Inputs
                key={"name"}
                type={"text"}
                label={"Question Name"}
                name={"questionName"}
                isRequired={true}
                value={formValues["questionName"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <select className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" required value={selectedFunnel} onChange={(e) => setSelectedFunnel(e.target.value)}>
                <option value="" selected>Select one</option>
                {funnels?.map((funnel) => (
                  <option value={funnel?.id}>{funnel?.name}</option>
                ))}
              </select>
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.questionName &&
                      selectedFunnel &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isLoading ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}
        <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these questions?"
        onConfirm={handleBulkDelete}
      // loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "activate" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate these questions?"
        onConfirm={handleBulkActivate}
      // loading={deletingUser}
      />
    </div>
  );
}

export default QuestionsHome;
