import React from "react";
import Table from "../../../CustomUIElements/Table/Table";

function UsersTable({data, loading}) {
  return (
    <>
      <Table
        header={[
          { name: "First Name", identifier: "firstName" },
          { name: "Last Name", identifier: "lastName" },
          { name: "Email", identifier: "email" },
        ]}
        data={data}
        options={{ variant: "primary", tableStyle: "", allowSorting: false }}
        loading={loading}
      />
    </>
  );
}

export default UsersTable;
