import React from "react";
import Table from "../../../CustomUIElements/Table/Table";

function InvestorsTable({ data, loading }) {
  return (
    <>
      <Table
        header={[
          { name: "Name", identifier: "name" },
          { name: "Email", identifier: "email" },
          // { name: "Phone Number", identifier: "phoneNumber" },
        ]}
        data={data}
        options={{ variant: "primary", tableStyle: "striped-even" }}
        loading={loading}
      />
    </>
  );
}

export default InvestorsTable;
