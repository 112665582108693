import React, { useRef, useState } from "react";
import {
  AiOutlineSearch,
  AiFillHome,
  AiOutlinePlus,
  AiFillBulb,
  AiFillBell,
} from "react-icons/ai";
import useAuthStore from "../../store/authStore";
import NavbarPopup from "./NavbarPopup";
import AccountPopup from "./AccountPopup";
import NotificationPopUp from "./NotificationPopUp";
import SearhBar from "./SearhBar";
import ModalContainer from "../../CustomUIElements/Modal/ModalContainer";
import PointsModal from "../../CustomUIElements/Modal/PointsModal";
import { useNavigate } from "react-router-dom";
import CreateIdea from "../Ideas/CreateIdea/CreateIdea";
import AccountSettings from "../Settings/AccountSettings";
import FeedbackModal from "../../CustomUIElements/Modal/FeedbackModal";
import { useQuery } from "react-query";
import { fetchNotifications } from "../../utils/fetchNotifications";
import useOutsideAlerter from "../../libs/useOutsideClick";

function DashboardNavbar() {
  const [openPointsModal, setOpenPointsModal] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [settingsTab, setSettingsTab] = useState("");
  const { userProfile, saveUserNotifications } = useAuthStore();

  const getPathName = () => {
    return window.location.pathname;
  };

  const navigate = useNavigate();
  const profileRef = useRef()

  useOutsideAlerter(profileRef, setOpenProfileModal)

  const { isLoading, isRefetching, refetch } = useQuery(
    "fetchNotifications",
    fetchNotifications,
    {
      enabled: false,
      onSuccess: ({ data }) => {
        saveUserNotifications(data.data);
      },
    }
  );

  return (
    <header className="fixed left-0 right-0 md:sticky top-0 bg-white z-50">
      <div className="max-w-[90%] mx-auto flex items-center justify-between">
        <div className="max-h-auto  md:max-h-[8rem] flex justify-between items-center uppercase py-[1.4rem] md:py-[2.4rem] w-fit">
          <a href="/">
            <img
              src="/assets/logo.svg"
              alt="HYDREATE"
              className="md:ml-6 lg:ml-0"
            />
          </a>
        </div>
        <div className="flex items-center space-x-8 relative">
          <div className="flex items-center gap-8 pr-8 border-r border-[#e3e3e3] relative">
            {openSearchBar && <SearhBar setOpenSearchBar={setOpenSearchBar} />}
            {!openSearchBar && (
              <AiOutlineSearch
                size={24}
                className="cursor-pointer hidden"
                onClick={() => setOpenSearchBar(true)}
              />
            )}
            <img
              src="/assets/home.svg"
              alt="Home icon"
              className="w-[3rem] h-[3.2rem] cursor-pointer hidden"
              onClick={() => setOpenPointsModal(true)}
            />
          </div>
          <div className="flex items-center gap-8 relative">
            <AiFillHome
              size={20}
              color={getPathName() === "/dashboard" && "#008FD2"}
              className="cursor-pointer"
              onClick={() => navigate("/dashboard")}
            />
            <AiOutlinePlus
              size={20}
              className="cursor-pointer"
              onClick={() => setOpenCreateModal(true)}
            />
            <AiFillBulb
              size={20}
              className="cursor-pointer"
              color={getPathName() === "/my-ideas" && "#008FD2"}
              onClick={() => navigate("/my-ideas")}
            />
            {/* <AiFillBell
              size={20}
              className="cursor-pointer"
              color={openNotificationModal && "#008FD2"}
              onClick={() => {
                setOpenNotificationModal(!openNotificationModal);
                setOpenProfileModal(false);
                !openNotificationModal && refetch();
              }}
            /> */}
            <div
              className="w-[4rem] h-[4rem] text-[2rem] text-white bg-[#008FD2] rounded-full flex justify-center items-center cursor-pointer"
              onClick={() => {
                setOpenProfileModal(!openProfileModal);
                setOpenNotificationModal(false);
              }}
              ref={profileRef}
            >
              {userProfile?.user?.documents?.length > 0 ?
                <img src={userProfile?.user?.documents[0]?.path} alt="Profile" className="w-full h-[4rem] object-cover rounded-full" />
                :
                <p>
                  {userProfile?.user?.firstName[0]}
                  {userProfile?.user?.lastName[0]}
                </p>
              }

              {openProfileModal && (
                <NavbarPopup>
                  <AccountPopup
                    userProfile={userProfile}
                    setOpenSettings={setOpenSettings}
                    setSettingsTab={setSettingsTab}
                    openFeedback={openFeedback}
                    setOpenFeedback={setOpenFeedback}
                  />
                </NavbarPopup>
              )}
            </div>
          </div>
          {openNotificationModal && (
            <NavbarPopup>
              <NotificationPopUp
                userProfile={userProfile}
                isLoading={isLoading}
                isRefetching={isRefetching}
              />
            </NavbarPopup>
          )}
        </div>
      </div>
      {openPointsModal && (
        <ModalContainer>
          <PointsModal setOpenPointsModal={setOpenPointsModal} />
        </ModalContainer>
      )}
      {openFeedback && (
        <ModalContainer height="auto">
          <FeedbackModal setOpenFeedback={setOpenFeedback} />
        </ModalContainer>
      )}
      {openCreateModal && (
        <CreateIdea
          setOpenCreateModal={setOpenCreateModal}
          openCreateModal={openCreateModal}
        />
      )}
      {openSettings && (
        <AccountSettings
          setOpenSettings={setOpenSettings}
          settingsTab={settingsTab}
          setSettingsTab={setSettingsTab}
        />
      )}
    </header>
  );
}

export default DashboardNavbar;