import React, { useState } from "react";
import { MdSettings, MdHealthAndSafety } from "react-icons/md";
import { RxCaretRight } from "react-icons/rx";
import { RiQuestionnaireFill } from "react-icons/ri";
import { GiEntryDoor } from "react-icons/gi";
import { IoIosCard } from "react-icons/io";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchSubscriptions } from "../../utils/fetchSubscriptions";
import { FaStar } from "react-icons/fa";

function AccountPopup({
  setOpenSettings,
  setSettingsTab,
  openFeedback,
  setOpenFeedback,
}) {
  const { removeUser, userProfile } = useAuthStore();
  const navigate = useNavigate()
  const [sub, setSub] = useState([])

  const { } = useQuery("fetchSubscriptions", fetchSubscriptions, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      setSub(data?.data)
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser()
        window.location.href = "/login"
      }
    }
  })

  const findSubColor = () => {
    if (sub) {
      const color = sub?.find((s) => s?.topic === userProfile?.user?.subscription)?.colourCode
      return color
    }
  }

  const handleClickEvent = (name) => {
    switch (name) {
      case "Log Out":
        window.localStorage.clear();
        removeUser();
        break;
      case "Account Settings":
        setSettingsTab("Personal Details");
        setOpenSettings(true);
        break;
      case "Security and Privacy":
        setSettingsTab("Security and Privacy");
        setOpenSettings(true);
        break;
      case "Send Feedback":
        setOpenFeedback(true);
        break;
      case "Payment History":
        setSettingsTab("Payment History");
        setOpenSettings(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className=" p-4 flex gap-[1.2rem] items-center">
          <div className="flex justify-between items-center">
            <div className="w-[4rem] h-[4rem] text-[2rem] text-white bg-[#008FD2] p-2 rounded-full flex justify-center items-center pt-3 pl-[.6rem]">
              {userProfile?.user?.firstName[0]}
              {userProfile?.user?.lastName[0]}
            </div>
          </div>
          <p className="text-[#103240] text-[1.8rem] font-medium">
            {userProfile?.user?.firstName} {userProfile?.user?.lastName}
          </p>
        </div>
        <p className="text-[2rem] mr-5"><FaStar color={findSubColor()} /></p>
      </div>
      <div className="space-y-[.5rem]">
        {PopupList.map(({ name, icon }) => (
          <div
            className="p-4  flex justify-between items-center cursor-pointer hover:bg-[#F7F7F7]"
            key={name}
            onClick={() => handleClickEvent(name)}
          >
            <div className="flex gap-[1.2rem] items-center">
              {icon}
              <p className="text-[#103240] text-[1.6rem]">{name}</p>
            </div>
            <span className="cursor-pointer">
              <RxCaretRight size={20} color="#103240" />
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

export default AccountPopup;

export const PopupList = [
  {
    name: "Account Settings",
    icon: <MdSettings size={20} color="#103240" />,
  },
  {
    name: "Security and Privacy",
    icon: <MdHealthAndSafety size={20} color="#103240" />,
  },
  {
    name: "Send Feedback",
    icon: <RiQuestionnaireFill size={20} color="#103240" />,
  },
  {
    name: "Payment History",
    icon: <IoIosCard size={20} color="#103240" />,
  },
  {
    name: "Log Out",
    icon: <GiEntryDoor size={20} color="#103240" />,
  },
];
