import React, { useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { LiaTimesSolid } from "react-icons/lia";
import AddCategoryForm from "./AddCategoryForm";
import { useMutation } from "react-query";
import { deleteCategory } from "../../../utils/deleteCategory";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import useCategoriesStore from "../../../store/categoriesStore";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";

function AllcategoriesTable({ Data, loading, fetchCategories, openModal, setOpenModal, selectedRows,
  setSelectedRows, searchQuery }) {
  const [formValues, setFormValues] = useState({});
  const [rowData, setRowData] = useState(null);
  const { removeUser } = useAuthStore()
  const navigate = useNavigate()
  const { Categories, saveCategories } = useCategoriesStore();
  const { mutate, isLoading: deleting } = useMutation(() => deleteCategory(rowData?.id), {
    onSuccess: ({ data }) => {
      fetchCategories()
      toast.success("Idea deleted successfully")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Categories", rowData?.id), {
    onSuccess: ({ data }) => {
      fetchCategories()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  return (
    <>
      <Table
        header={[
          { name: "Title", identifier: "name" },
          { name: "Description", identifier: "description" },
        ]}
        data={Data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "enable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this Category?"
        onConfirm={() => activateUser()}
        loading={activatingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "disable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this Category?"
        onConfirm={() => mutate()}
        loading={deleting}
      />
      {openModal.modalName === "edit" && openModal.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Edit Category Form</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
              />
            </div>
            <AddCategoryForm fetchCategories={fetchCategories} setOpenModal={setOpenModal} mode="edit" rowData={rowData} />
          </div>
        </ModalContainer>
      )}
      {openModal.modalName === "view" && openModal.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Category Information</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
              />
            </div>
            <AddCategoryForm fetchCategories={fetchCategories} setOpenModal={setOpenModal} mode="view" rowData={rowData} />
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default AllcategoriesTable;
