import React, { useState } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../../store/authStore';
import { useMutation, useQuery } from 'react-query';
import { fetchFunnels } from '../../../utils/fetchFunnels';
import ModalContainer from '../../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../../CustomUIElements/Inputs/Inputs';
import { addFunnel } from '../../../utils/addFunnel';
import { toast } from 'react-toastify';
import { HiOutlineSearch } from 'react-icons/hi';
import SettingsTable from './SettingsTable';
import { fetchSettings } from '../../../utils/fetchSettings';
import { addSetting } from '../../../utils/addSetting';
import ConfirmationModal from '../../../CustomUIElements/Modal/ConfirmationModal';
import { activateEntityStatus } from '../../../utils/activateEntityStatus';
import { deleteSetting } from '../../../utils/deleteSetting';

function SettingsHome() {
    const [openModal, setOpenModal] = useState(false);
    const [formValues, setFormValues] = useState({});
    const { removeUser } = useAuthStore()
    // const [isLoading, setIsLoading] = useState(false);
    const [settings, setSettings] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const { isLoading: loadingFunnels, isRefetching, refetch } = useQuery("fetchSettings", fetchSettings, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setSettings(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })

    const { mutate, isLoading } = useMutation(addSetting, {
        onSuccess: ({ data }) => {
            setOpenModal(false)
            toast.success(`${data.status}`)
            setFormValues({})
            refetch()
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            name: formValues?.settingName,
            code: formValues?.settingCode,
            description: formValues?.settingDesc,
            value: parseInt(formValues?.settingVal),
            jsonValue: ""
        });
    };

    const mutation = useMutation(deleteSetting);

    const handleBulkDelete = async () => {
        try {
            await Promise.all(selectedRows?.map(async (row) => {
                // Call the mutation function with the ID
                await mutation.mutateAsync(row?.id);
            }));
            toast.success("Operation completed successfully");
            refetch();
            setOpenModal({ modalName: "", showModal: false });
        } catch (error) {
            console.error("Bulk delete error:", error);
            toast.error("An error occurred during bulk delete operation");
        }
    };
    const activateMutation = useMutation({
        mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
    })
    const handleBulkActivate = async () => {
        try {
            await Promise.all(selectedRows?.map(async (row) => {
                // Call the mutation function with the ID
                console.log(row?.id)
                await activateMutation.mutateAsync({ entityName: "Settings", id: row?.id });
            }));
            toast.success("Operation completed successfully");
            refetch();
            setOpenModal({ modalName: "", showModal: false });
        } catch (error) {
            console.error("Bulk delete error:", error);
            toast.error("An error occurred during bulk activate operation");
        }
    };

    return (
        <div className='min-h-[85vh]'>
            <div className="flex justify-between items-center  mb-[2rem]">
                <div className="flex items-center gap-4">
                    <h1 className="text-[20px] font-semibold">Manage Settings</h1>
                    <div className="relative">
                        <input
                            className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <span className="absolute top-[14px] left-[15px] text-[20px]">
                            <HiOutlineSearch />
                        </span>
                    </div>
                </div>
                <div className="w-fit">
                    <Buttons
                        type="button"
                        text="Add New Settting"
                        variant="primary"
                        handleClick={() => setOpenModal(true)}
                    />
                </div>
            </div>
            {selectedRows?.length > 0 &&
                <div className="flex gap-2 items-center">
                    <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
                    <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
                </div>
            }
            <div className="mt-4 mb-8">
                <SettingsTable data={settings} loading={loadingFunnels || isRefetching} refetch={refetch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
            </div>
            {openModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem]  mb-4">
                            <h3 className="">Add New Setting</h3>
                            <LiaTimesSolid
                                onClick={() => { setOpenModal(false); setFormValues({}) }}
                                className="cursor-pointer"
                            />
                        </div>
                        <form className="space-y-[1.6rem]"
                            onSubmit={handleSubmit}
                        >
                            <Inputs
                                key={"settingName"}
                                type={"text"}
                                label={"Name of Setting"}
                                name={"settingName"}
                                isRequired={true}
                                value={formValues["settingName"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"settingDesc"}
                                type={"text"}
                                label={"Description of Setting"}
                                name={"settingDesc"}
                                isRequired={true}
                                value={formValues["settingDesc"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"settingCode"}
                                type={"text"}
                                label={"Code"}
                                name={"settingCode"}
                                isRequired={true}
                                value={formValues["settingCode"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"settingVal"}
                                type={"text"}
                                label={"Value of Setting"}
                                name={"settingVal"}
                                isRequired={true}
                                value={formValues["settingVal"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <textarea className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem] resize-none" placeholder='JSON Value' value={formValues?.jsonValue} name='jsonValue' onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    [e.target.name]: e.target.value,
                                })
                            }} />
                            <div className="!mt-[3rem]">
                                <Buttons
                                    variant={
                                        formValues?.settingName &&
                                            formValues?.settingDesc &&
                                            formValues?.settingVal &&
                                            !isLoading
                                            ? "primary"
                                            : "disabled"
                                    }
                                    type="submit"
                                    text={isLoading ? "Submitting..." : "Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>
            )}
            <ConfirmationModal
                showModal={openModal.modalName === "delete" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to delete these settings?"
                onConfirm={handleBulkDelete}
            // loading={deletingUser}
            />
            <ConfirmationModal
                showModal={openModal.modalName === "activate" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate these settings?"
                onConfirm={handleBulkActivate}
            // loading={deletingUser}
            />
        </div>

    )
}

export default SettingsHome