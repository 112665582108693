import React, { useEffect, useState } from 'react'
import Inputs from '../../../CustomUIElements/Inputs/Inputs'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createIdea } from '../../../utils/createIdea';
import useAuthStore from '../../../store/authStore';
import { useNavigate } from 'react-router-dom';
import { addCategory } from '../../../utils/addCategory';
import { updateCategory } from '../../../utils/updateCategory';

function AddCategoryForm({ fetchCategories, setOpenModal, mode, rowData }) {
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    colour: "",
  })
  const { removeUser } = useAuthStore()
  const navigate = useNavigate()

  const { mutate, isLoading: creatingIdea } = useMutation(addCategory, {
    onSuccess: ({ data }) => {
      toast.success(`${data.status}`);
      fetchCategories()
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      console.log(response)
      if (response.status === 500) {
        toast.error(`${response?.data}`);
      } else {
        toast.error(`${response?.statusText}`);
      }
      if (response.status === 401) {
        removeUser();
        navigate("/login");
      }
    },
  });

  const { mutate: update, isLoading: updatingIdea } = useMutation(updateCategory, {
    onSuccess: ({ data }) => {
      toast.success(`${data.status}`);
      fetchCategories()
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      console.log(response)
      if (response.status === 500) {
        toast.error(`${response?.data}`);
      } else {
        toast.error(`${response?.statusText}`);
      }
      if (response.status === 401) {
        removeUser();
        navigate("/login");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      name: formValues?.name,
      description: formValues?.description,
      colour: formValues?.colour,
    });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    update({
      id: formValues?.id,
      name: formValues?.name,
      description: formValues?.description,
      colour: formValues?.colour,
    });
  }

  useEffect(() => {
    if (mode === "edit" || mode === "view") {
      setFormValues({
        name: rowData?.name,
        description: rowData?.description,
        colour: rowData?.colour,
        id: rowData?.id
      })
    }
  }, [])
  return (
    <form className='space-y-[1.6rem]' onSubmit={mode === "edit" ? handleUpdateSubmit : handleSubmit}>
      <Inputs type="text" label="Category Name" isRequired={true} name="name" handleChange={(e) =>
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
        })
      } value={formValues?.name} readOnly={mode === "view"} />
      <Inputs type="text" label="Category Description" isRequired={true} name="description" handleChange={(e) =>
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
        })
      } value={formValues?.description} readOnly={mode === "view"} />
      <Inputs type="text" label="Category Colour" isRequired={true} name="colour" handleChange={(e) =>
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
        })
      } value={formValues?.colour} readOnly={mode === "view"} />
      {mode !== "view" &&
        <div className="!mt-[3rem]">
          <Buttons
            variant={
              formValues?.name &&
                formValues?.description &&
                formValues?.colour &&
                !creatingIdea
                ? "primary"
                : "disabled"
            }
            type="submit"
            text={mode === "edit" ? updatingIdea ? "Submitting..." : "Submit" : creatingIdea ? "Submitting..." : "Submit"}
          />
        </div>
      }
    </form>
  )
}

export default AddCategoryForm