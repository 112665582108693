import React, { useEffect, useState } from "react";
import GetStarted from "../Components/GetStarted/GetStarted";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AccountType from "../Components/GetStarted/AccountType";
import InvestorType from "../Components/GetStarted/InvestorType";
import UserType from "../Components/GetStarted/UserType";
import useAuthStore from "../store/authStore";

function GetStartedPage() {
  const [investorTypeStep, setInvestorTypeStep] = useState(1);
  const [step, setNextStep] = useState("account-type");
  const [formValues, setFormValues] = useState({});
  const {userProfile} = useAuthStore()
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userProfile) {
      return navigate("/dashboard");
    }
  }, []);


  return (
    <>
      <div
        className={`app-container md:w-[46rem] mx-auto h-[2.4rem] mb-[1.8rem] flex items-center justify-between`}
      >
        <FiArrowLeft
          size={24}
          className={`cursor-pointer`}
          onClick={() => {
            if (step === "account-type") {
              navigate(-1);
            } else if (step === "user-type") {
              setNextStep("account-type");
            } else if (step === "create") {
              setNextStep("user-type");
            } else if (step === "investor-type") {
              if (investorTypeStep > 1) {
                setInvestorTypeStep((prev) => prev - 1);
              } else {
                setNextStep("account-type");
              }
            } else {
              navigate(-1);
              setNextStep((prev) => prev);
            }
          }}
        />
        {step === "investor-type" && (
          <span className="text-[#008FD2] text-[1.8rem] font-medium flex">
            <p className="font-bold text-[2.4rem]">{investorTypeStep}</p>/4
          </span>
        )}
      </div>
      <div className="app-container  md:w-[46rem] mx-auto flex flex-col mb-[5rem] ">
        {step === "account-type" && (
          <AccountType
            formValues={formValues}
            setFormValues={setFormValues}
            setNextStep={setNextStep}
          />
        )}
        {step === "user-type" && (
          <UserType
            formValues={formValues}
            setFormValues={setFormValues}
            setNextStep={setNextStep}
          />
        )}
        {step === "create" && (
          <GetStarted formValues={formValues} setFormValues={setFormValues} />
        )}
        {step === "investor-type" && (
          <InvestorType
            formValues={formValues}
            setFormValues={setFormValues}
            investorTypeStep={investorTypeStep}
            setInvestorTypeStep={setInvestorTypeStep}
          />
        )}
      </div>
    </>
  );
}

export default GetStartedPage;
