import apiClient from "../libs/http-common";
export const updateIdea = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.put("/Ideas/update", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
