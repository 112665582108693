import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import AddNote from "./AddNote";
import NoteCard from "./NoteCard";
import CreateIdeaHeader from "../../CreateIdea/CreateIdeaHeader";
import CreateIdeaContent from "../../CreateIdea/CreateIdeaContent";
import BorderedInputs from "../../../../CustomUIElements/Inputs/BorderInputs";
import BorderedTextArea from "../../../../CustomUIElements/Inputs/BorderTextArea";
import { Buttons } from "../../../../CustomUIElements/Buttons/Buttons";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { addNote } from "../../../../utils/addNote";
import useAuthStore from "../../../../store/authStore";
import { useNavigate, useParams } from "react-router-dom";
import { fetchMyNotes } from "../../../../utils/fetchMyNotes";
import { fetchIdeaScore } from "../../../../utils/fetchIdeaScore";
import useIdeaStore from "../../../../store/ideaStore";

function NotesSection({ notes, setNotes }) {
  const { id } = useParams();
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const { removeUser } = useAuthStore();
  const navigate = useNavigate();
  const { refetch: fetchNotes } = useQuery(
    "fetchMyNotes",
    () => fetchMyNotes({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setNotes(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );

  const { mutate, isLoading } = useMutation(addNote, {
    onSuccess: ({ data }) => {
      toast.success(`${data.status}`);
      setOpenNoteModal(false);
      setTitle("");
      setNote("");
      fetchNotes();
    },
    onError: ({ response }) => {
      console.log(response);
      toast.error(`${response?.statusText}`);
      if (response.status === 401) {
        removeUser();
        navigate("/login");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      ideaId: parseInt(id),
      title,
      text: note,
    });
  };
  return (
    <div className="w-[65%] ">
      <div className="flex justify-between items-center">
        <h4 className="text-[#103240] text-[1.6rem] font-semibold">
          All Notes <span className="text-[#103240B2]">({notes?.length})</span>
        </h4>
        <span
          className="flex items-center text-[#008FD2] text-[1.4rem] cursor-pointer"
          onClick={() => setOpenNoteModal(true)}
        >
          <BsPlus size={20} />
          <p>Add new note</p>
        </span>
      </div>
      <div className="flex gap-[1.6rem]">
        <div className="flex gap-[1.6rem] max-w-[65%] overflow-x-scroll">
        {notes?.map((note) => (
          <NoteCard key={note?.id} note={note} />
        ))}
        </div>
        <AddNote setOpenNoteModal={setOpenNoteModal} />
      </div>
      {openNoteModal && (
        <div className="fixed inset-0 z-50 h-screen bg-white">
          <CreateIdeaHeader setOpenCreateModal={setOpenNoteModal} action="Add New Note" />
          <div className="w-[65rem] mx-auto mt-[4.8rem]">
            <CreateIdeaContent>
              <form onSubmit={handleSubmit}>
                <div className="mb-[3rem]">
                  <BorderedInputs
                    label="Enter Title"
                    value={title}
                    handleChange={(e) => setTitle(e.target.value)}
                    isRequired
                  />
                </div>
                <BorderedTextArea
                  label="Enter Note"
                  value={note}
                  handleChange={(e) => setNote(e.target.value)}
                  isRequired
                />
                <div className="flex justify-end ml-auto mt-4 ">
                  <div className="flex gap-[1.6rem]">
                    <Buttons
                      text="Cancel"
                      type="button"
                      variant="cancel"
                      handleClick={() => {
                        setTitle("");
                        setNote("");
                        setOpenNoteModal(false);
                      }}
                    />
                    <Buttons
                      type="submit"
                      text="Save"
                      variant={
                        isLoading || (title.length < 1 && note.length < 1)
                          ? "disabled"
                          : "primary"
                      }
                    />
                  </div>
                </div>
              </form>
            </CreateIdeaContent>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotesSection;
