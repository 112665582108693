import React from "react";
import IdeaHome from "../../Components/AdminDashboard/ideas/ideaHome";

function ManageIdea() {
  return (
    <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1]">
      <IdeaHome/>
    </div>
  );
}

export default ManageIdea;
