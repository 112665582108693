import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { LiaTimesSolid } from "react-icons/lia";
import { updateQuestion } from "../../../utils/updateQuestion";
import useAuthStore from "../../../store/authStore";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { deleteQuestion } from "../../../utils/deleteQuestion";
// import ViewModal from "../../../CustomUIElements/Modal/viewModal";

function AllQuestionsTable({ selectedRows, setSelectedRows, data, loading, funnels, refetch }) {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const [selectedFunnel, setSelectedFunnel] = useState("")

  const { removeUser } = useAuthStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (rowData) {
      setFormValues({
        questionName: rowData?.name || ""
      })
      setSelectedFunnel(rowData?.funnelId || "")
    }
  }, [rowData])

  const updateQuestionHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    await updateQuestion({
      id: rowData?.id,
      name: formValues?.questionName,
      funnelId: parseInt(selectedFunnel),
      isAnswered: false
    }).then(() => {
      setSelectedFunnel("")
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      if (err?.response?.status === 401) {
        removeUser()
        window.localStorage.clear()
        window.location.href = "/login"
      }
    })
  }

  const { mutate: deleteAnIdea, isLoading: deletingIdea } = useMutation(() => deleteQuestion(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      setOpenModal({ modalName: "", showModal: false })
      toast.success("Question deleted successfully")
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Questions", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  return (
    <>
      <Table
        header={[
          { name: "Questions", identifier: "name" },
        ]}
        data={data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        loading={loading}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "disable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this question?"
        loading={deletingIdea}
        onConfirm={() => deleteAnIdea()}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "enable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this question?"
        loading={activatingUser}
        onConfirm={() => activateUser()}
      />


      {openModal.modalName === "edit" && openModal.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Edit a question</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
              />
            </div>
            <form className="space-y-[1.6rem]" onSubmit={updateQuestionHandler}>
              <Inputs
                key={"name"}
                type={"text"}
                label={"Question Name"}
                name={"questionName"}
                isRequired={true}
                value={formValues["questionName"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <select className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" required value={selectedFunnel} onChange={(e) => setSelectedFunnel(e.target.value)}>
                <option value="" selected>Select one</option>
                {funnels?.map((funnel) => (
                  <option value={funnel?.id}>{funnel?.name}</option>
                ))}
              </select>
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.questionName &&
                      selectedFunnel &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isLoading ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}

      {openModal.modalName === "view" && openModal.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Idea</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
              />
            </div>
            <div className="space-y-[1.6rem]">
              <Inputs
                key={"name"}
                type={"text"}
                label={"Question Name"}
                name={"questionName"}
                value={rowData["name"]}
                readOnly={true}
              />
              <Inputs
                key={"name"}
                type={"text"}
                label={"Funnel Name"}
                name={"funnelName"}
                value={funnels?.find((f) => f?.id === rowData?.funnelId)?.name}
                readOnly={true}
              />
            </div>
          </div>
        </ModalContainer>
      )}

    </>
  );
}

export default AllQuestionsTable;
