
import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import { useTheme } from "../../context/ThemeContext";
import useAuthStore from "../../store/authStore";
import { AdminLinks, InvestorLink } from "./SidebarList";

function Sidebar() {
  const { theme } = useTheme();
  const location = useLocation();
  const { userProfile, removeUser } = useAuthStore()
  const navigate = useNavigate();

  const themeStyles = {
    light: {
      backgroundColor: "#008FD2",
      color: "#fff",
    },
    dark: {
      backgroundColor: "#333",
      color: "#f0f0f0",
    },
    blue: {
      backgroundColor: "#fff",
      color: "#008FD2",
    },
  };

  const activeStyle = {
    backgroundColor: "#fff",
    color: "#008FD2",
  };

  return (
    <div
      className={`fixed w-2/12`}
      style={{ ...themeStyles[theme], minHeight: "100vh" }}
    >
      <a href="/" className="flex justify-center py-1 border-b pb-0 mb-8">
        <img
          src="/assets/logo-white.png"
          alt=""
          className="w-full h-full scale-50"
        />
      </a>
      <ul className="">
        {(userProfile?.user?.userRole === "Administrator" ? AdminLinks : InvestorLink)?.map((link) => (
          <NavLink to={link?.url} activeStyle={activeStyle}>
            <li className={`mx-8 p-4 text-[1.3rem] hover:bg-white hover:bg-opacity-40 rounded-lg cursor-pointer flex gap-2 items-center ${location.pathname === link?.url && 'bg-white bg-opacity-40'} '}`}>
              {link?.icon}
              {link?.name}
            </li>
          </NavLink>
        ))}
      </ul>
      <div className="absolute bottom-[20px] w-full">
        <span className={`mx-8 p-4 text-[1.4rem] hover:bg-white hover:bg-opacity-40 rounded-lg cursor-pointer flex gap-2 items-center`} onClick={() => {
          window.localStorage.clear();
          removeUser();
          navigate("/login")
        }}>
          <CiLogout size={20} />
          Logout
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
