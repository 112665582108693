import React, { useState } from "react";
import useAuthStore from "../../store/authStore";
import { AiOutlinePlus } from "react-icons/ai";
import CreateIdea from "../Ideas/CreateIdea/CreateIdea";
import IdeaBanner from "./IdeaBanner";
import useIdeaStore from "../../store/ideaStore";
import { useQuery } from "react-query";
import { fetchAllIdeas } from "../../utils/fetchAllIdeas";
import IdeasCard from "./IdeasCard";
import { fetchCategories } from "../../utils/fetchCategories";
import FloatingAdd from "../../CustomUIElements/FloatingIcons/FloatingIcon";
import { Link } from "react-router-dom";
import { fetchMyIdeas } from "../../utils/fetchMyIdeas";

function DashBoard() {
  const { userProfile, removeUser } = useAuthStore();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { ideas, saveIdeas, saveCategories } = useIdeaStore();
  const { isLoading: loadingIdeas } = useQuery("fetch ideas", fetchMyIdeas, {
    onSuccess: ({ data }) => {
      saveIdeas(data);
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser();
      }
    },
  });
  const { isError, isLoading: loadingCategories } = useQuery(
    "fetchCategories",
    fetchCategories,
    {
      onSuccess: ({ data }) => {
        saveCategories(data.data);
      },
      onError: ({ response }) => {
        console.log(`${response?.data?.status}`);
        if (response?.data?.status === 401) {
          removeUser();
        }
      },
    }
  );

  const mostRecentIdea = ideas?.data && ideas?.data[0];

  return (
    <div className="app-container mt-[3.2rem]">
      <div className="w-[82rem] mx-auto pb-[1.7rem] mb-[8rem]">
        <p className="text-[3.2rem] font-medium text-[#103240]">
          Hi{" "}
          <span className="font-semibold">{userProfile?.user?.firstName}</span>
        </p>
        <IdeaBanner />
        <div className="flex justify-between items-center mt-[3.2rem] mb-[1.6rem]">
          <h3 className="text-[2rem] text-[#103240] font-semibold">
            Most Recent Idea
          </h3>
          <Link to="/my-ideas">
            <p className="text-[#103240B2] text-[1.6rem]">See all</p>
          </Link>
        </div>
        {mostRecentIdea && (
          <IdeasCard
            name={mostRecentIdea?.name}
            category={mostRecentIdea?.categoryId}
            dateAdded={mostRecentIdea?.dateAdded}
            id={mostRecentIdea?.id}
            progressScore={mostRecentIdea?.progressScore}
          />
        )}
      </div>
      <FloatingAdd
        icon={<AiOutlinePlus color="white" size={20} />}
        handleClick={() => setOpenCreateModal(true)}
      />
      {openCreateModal && (
        <CreateIdea setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} />
      )}
    </div>
  );
}

export default DashBoard;
