import apiClient from "../libs/http-common";
export const deleteInvestor = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient({
    url: "/Investors/delete",
    method: "delete",
    data: { id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};