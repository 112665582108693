import React, { useState } from "react";
import AllUsersTable from "./AllUsersTable";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { LiaTimesSolid } from "react-icons/lia";
import { HiOutlineSearch } from "react-icons/hi";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import useAuthStore from "../../../store/authStore";
import { useMutation, useQuery } from "react-query";
import { createUserAccount } from "../../../utils/userAccountFn";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchUsers } from "../../../utils/fetchUsers";
import { deleteUser } from "../../../utils/deleteUser";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";

function UsersAdministration() {
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false
  });
  const [formValues, setFormValues] = useState({});
  const [users, setUsers] = useState([])
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  const { removeUser } = useAuthStore();
  const navigate = useNavigate()
  const { isLoading: loadingUsers, isRefetching, refetch } = useQuery(
    "fetchUsers",
    fetchUsers,
    {
      enabled: true,
      onSuccess: ({ data }) => {
        setUsers(data.data);
      },
      onError: ({ response }) => {
        console.log('response', response);
        if (response?.status === 401) {
          removeUser()
          navigate("/login")
        }
      }
    }
  );
  const { mutate, isLoading } = useMutation(createUserAccount, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal(false)
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });

  const mutation = useMutation(deleteUser);

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        await mutation.mutateAsync(row?.id);
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk delete operation");
    }
  };
  const activateMutation = useMutation({
    mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
  })
  const handleBulkActivate = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        console.log(row?.id)
        await activateMutation.mutateAsync({ entityName: "Users", id: row?.id });
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk activate operation");
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      organisationName: formValues?.organisationName,
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      emailAddress: formValues?.email,
      mobileNumber: formValues?.phoneNumber,
      password: formValues?.password,
      confirmPassword: formValues?.confirmPassword,
      isInvestor: false
    });
  };
  return (
    <div className="h-fit">
      <div className="flex justify-between items-center mb-[2rem]">
        <div className="flex items-center gap-4">
          <h1 className="text-[20px] font-semibold">Manage Users</h1>
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
        <div className="w-fit">
          <Buttons
            type="button"
            text="Add New User"
            variant="primary"
            handleClick={() => setOpenModal({ modalName: "add", showModal: true })}
          />
        </div>
      </div>
      {selectedRows?.length > 0 &&
        <div className="flex gap-2 items-center">
          <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
          <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
        </div>
      }
      <div className="mt-4 mb-8">
        <AllUsersTable loading={loadingUsers || isRefetching} data={users} setUsers={setUsers} selectedRows={selectedRows}
          setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
      </div>
      {openModal.modalName == "add" && openModal.showModal && (
        <ModalContainer>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Add New User</h3>
              <LiaTimesSolid onClick={() => setOpenModal(false)} className="cursor-pointer" />
            </div>
            <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
              {FormInputFields.map(({ name, label, type, isRequired }) => (
                <Inputs
                  key={name}
                  type={type}
                  label={label}
                  name={name}
                  isRequired={isRequired}
                  value={formValues[name]}
                  handleChange={(e) =>
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              ))}
              <Inputs
                key={"organisationName"}
                type="text"
                label={"Organisation Name"}
                name={"organisationName"}
                isRequired={true}
                value={formValues["organisationName"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />

              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.firstName &&
                      formValues?.lastName &&
                      formValues?.email &&
                      formValues?.phoneNumber &&
                      formValues?.password &&
                      formValues?.confirmPassword &&
                      formValues?.password === formValues?.confirmPassword &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isLoading ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}
      <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these users?"
        onConfirm={handleBulkDelete}
      // loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "activate" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate these users?"
        onConfirm={handleBulkActivate}
      // loading={deletingUser}
      />
    </div>
  );
}

export default UsersAdministration;
