import React, { useEffect, useState } from 'react'
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { toast } from 'react-toastify';
import parse from "html-react-parser"
import { fetchSubscriptions } from '../../utils/fetchSubscriptions';
import { makePayment } from '../../utils/makePayment';
import useAuthStore from '../../store/authStore';

function SubscriptionPlans() {
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const { userProfile } = useAuthStore()

    useEffect(() => {
        fetchSubscriptions().then((res) => {
            setSubscriptionPlans(res?.data?.data);
        })
    }, [])

    const makePaymentHandler = async (id) => {
        setLoading(true)
        const token = window.localStorage.getItem("token");
        if (!token) {
            setLoading(false)
            toast.warn("Please login to continue")
            window.location.href = "/login?return_to=/plans"
        } else {
            setLoading(true)
            await makePayment(id).then((res) => {
                setLoading(false)
                window.location.href = res?.data
            }).catch((err) => {
                setLoading(false)
                if (err?.response?.status == 401) {
                    window.location.href = "/login?return_to=/plans"
                }
            })
        }
    }


    return (
        <section className="w-full overflow-y-scroll h-[70vh] pb-[3rem] scrollbar-hide relative top-[-8rem]">
            <div className="max-w-[102rem] mx-auto flex flex-col justify-center">
                <div className="text-center mb-[4.6rem]">
                    <p className="bg-[#EDF4FC] text-[#008FD2] px-[1.2rem] py-[0.4rem] rounded-[1.5rem] font-bold text-center text-[1.8rem] mx-auto w-max mb-[2.6rem]">
                        Our Subscription Plans
                    </p>
                    <p className='text-[1.5rem] text-slate-500'>Choose plan that suits best for your business needs, Our plans scales with you based on your needs</p>
                </div>
                <div className='grid grid-cols-2 gap-12'>
                    {subscriptionPlans?.map((plans, index) => {
                        return (
                            <div key={index} className='w-full rounded-[.8rem] border'>
                                <div className=' w-full h-8 rounded-t-[.8rem]' style={{ background: plans?.colourCode }} />
                                <div className='bg-white p-12'>
                                    <div className='flex justify-between items-center mb-8'>
                                        <p className='font-bold text-[1.9rem]'>{plans?.topic}</p>
                                        {/* <span className='bg-green-200 text-green-700 px-2 py-1 rounded-md'>
                                            <p className='text-[1.2rem]'>For Individals</p>
                                        </span> */}
                                    </div>
                                    <div>
                                        <span className='flex gap-3 items-end'>
                                            <p className='font-bold text-[3rem]'>£{plans?.price}</p>
                                            <p className='text-[1.4rem] text-slate-500'>/ Per Month</p>
                                        </span>
                                        <div className='text-[1.5rem] mt-8 mb-4 text-slate-500'>
                                            {parse(plans?.description)}
                                        </div>
                                        <button className={`w-full text-[1.6rem] py-3 rounded-xl ${userProfile?.user?.subscription === plans?.topic ? "bg-gray-400 " : "bg-slate-200 "} hover:opacity-50`} onClick={() => makePaymentHandler(plans?.id)}
                                            disabled={userProfile?.user?.subscription === plans?.topic || loading}>{loading ? "Please wait..." : userProfile?.user?.subscription === plans?.topic ? "Active Plan" : "Choose Plan"}</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default SubscriptionPlans