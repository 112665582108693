import "./App.css";
import Homepage from "./pages/Homepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacypage from "./pages/Privacypage";
import TnCpage from "./pages/TnCpage";
import HomeLayout from "./layout/HomeLayout";
import PagesLayout from "./layout/PagesLayout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GetStartedPage from "./pages/GetStartedPage";
import LoginPage from "./pages/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "./layout/DashboardLayout";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import DashboardSuccess from "./pages/Dashboard/DashboardSuccess";
import DashboardHome from "./pages/Dashboard/DashboardHome";
import AllIdeasPage from "./pages/Ideas/AllIdeasPage";
import SingleIdeaPage from "./pages/Ideas/SingleIdeaPage";
import AdminDashboardLayout from "./layout/AdminDashboardLayout";
import { ThemeProvider } from "./context/ThemeContext";
import AdminDashboardHome from "./pages/Dashboard/AdminDashboardHome";
import ManageUsers from "./pages/Dashboard/ManageUsers";
import ManageInvestors from "./pages/Dashboard/ManageInvestors";
import ManageCategories from "./pages/Dashboard/ManageCategories";
import ManageIdea from "./pages/Dashboard/ManageIdeas";
import QuestionsHome from "./Components/AdminDashboard/questions/questionsHome";
import useAuthStore from "./store/authStore";
import ManageMyIdea from "./pages/Dashboard/ManageMyIdea";
import ManageFunnels from "./pages/Dashboard/ManageFunnels";
import PlansPage from "./pages/PlansPage";
import ManageSubscriptions from "./pages/Dashboard/ManageSubscriptions";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";
import EmailTemplates from "./pages/Dashboard/EmailTemplates";
import ManageInterests from "./pages/Dashboard/ManageInterests";
import ManageMyCategories from "./pages/Dashboard/ManageMyCategories";
import PaymentHistoryPage from "./pages/PaymentHistory";
import ManageSettings from "./pages/Dashboard/ManageSettings";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const USER_ROLES = {
  ADMIN: "admin",
  USER: "user",
};

function App() {
  const { userProfile } = useAuthStore();
  const getUserRole = () => {
    if (
      userProfile?.user?.userRole === "Administrator" ||
      userProfile?.user?.userRole === "Investor"
    ) {
      return USER_ROLES.ADMIN; // Replace this with your actual logic
    } else {
      return USER_ROLES.USER; // Replace this with your actual logic
    }
  };
  const userRole = getUserRole();
  console.log("user", userProfile);
  return (
    <ThemeProvider>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
      >
        <ToastContainer className="text-[1.6rem]" />
        <Router>
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<Homepage />} />
              <Route path="privacy-policy" element={<Privacypage />} />
              <Route path="terms-and-conditions" element={<TnCpage />} />
              <Route path="plans" element={<PlansPage />} />
              <Route path="payments/success" element={<SuccessPage />} />
              <Route path="payments/cancel" element={<CancelPage />} />
              <Route path="payment-history" element={<PaymentHistoryPage />} />
            </Route>
            <Route element={<PagesLayout />}>
              <Route path="get-started" element={<GetStartedPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="reset-password" element={<ResetPasswordPage />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/dashboard"
            >
              <Route
                index
                path="/dashboard"
                element={
                  userRole === USER_ROLES.USER ? (
                    <DashboardHome />
                  ) : (
                    <AdminDashboardHome />
                  )
                }
              />
              <Route path="welcome" element={<DashboardPage />} />
              <Route path="success" element={<DashboardSuccess />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-users"
            >
              <Route index element={<ManageUsers />} />
            </Route>

            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-investors"
            >
              <Route index element={<ManageInvestors />} />
            </Route>

            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-categories"
            >
              <Route index element={<ManageCategories />} />
            </Route>

            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-my-ideas"
            >
              <Route index element={<ManageMyIdea />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-ideas"
            >
              <Route index element={<ManageIdea />} />
            </Route>

            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-questions"
            >
              <Route index element={<QuestionsHome />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-funnels"
            >
              <Route index element={<ManageFunnels />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-subscriptions"
            >
              <Route index element={<ManageSubscriptions />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-settings"
            >
              <Route index element={<ManageSettings />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/email-templates"
            >
              <Route index element={<EmailTemplates />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-interests"
            >
              <Route index element={<ManageInterests />} />
            </Route>
            <Route
              element={
                userRole === USER_ROLES.USER ? (
                  <DashboardLayout />
                ) : (
                  <AdminDashboardLayout />
                )
              }
              path="/manage-my-categories"
            >
              <Route index element={<ManageMyCategories />} />
            </Route>

            <Route element={<DashboardLayout />} path="/my-ideas">
              <Route index path="/my-ideas" element={<AllIdeasPage />} />
              <Route path=":id" element={<SingleIdeaPage />} />
            </Route>
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
