import React from "react";
import SettingsHome from "../../Components/AdminDashboard/settings/Home";

function ManageSettings() {
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1]">
            <SettingsHome />
        </div>
    );
}

export default ManageSettings;
