import React from "react";
import Heading from "./Heading";
import Question from "./Question";

function QuestionSection({
  questions,
  setQuestions,
  singleIdea,
  setSingleIdea,
}) {
  return (
    <div>
      <Heading questions={questions} />
      {questions?.map((question) => (
        <Question
          key={question?.id}
          question={question}
          setQuestions={setQuestions}
          singleIdea={singleIdea}
          setSingleIdea={setSingleIdea}
        />
      ))}
    </div>
  );
}

export default QuestionSection;
