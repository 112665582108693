import React from "react";
import Summary from "../../Components/AdminDashboard/DashboardSumarry/Summary";

function AdminDashboardHome() {
  return (
    <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1]">
      <Summary />
    </div>
  );
}

export default AdminDashboardHome;
