import { BiCategory, BiHome } from "react-icons/bi";
import { BsQuestionSquare } from "react-icons/bs";
import { HiMiniBriefcase, HiOutlineLightBulb } from "react-icons/hi2";
import { LuUsers } from "react-icons/lu";
import {
  IoFunnelOutline,
  IoSettingsOutline,
  IoPricetagOutline,
} from "react-icons/io5";
import { CiMail } from "react-icons/ci";

export const AdminLinks = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "Manage Users",
    url: "/manage-users",
    icon: <LuUsers size={20} />,
  },
  {
    name: "Manage Investors",
    url: "/manage-investors",
    icon: <HiMiniBriefcase size={20} />,
  },
  {
    name: "My Ideas",
    url: "/manage-my-ideas",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "Manage Ideas",
    url: "/manage-ideas",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "Manage Categories",
    url: "/manage-categories",
    icon: <BiCategory size={20} />,
  },
  {
    name: "Manage Questions",
    url: "/manage-questions",
    icon: <BsQuestionSquare size={20} />,
  },
  {
    name: "Manage Funnels",
    url: "/manage-funnels",
    icon: <IoFunnelOutline size={20} />,
  },
  {
    name: "Manage Subscriptions",
    url: "/manage-subscriptions",
    icon: <IoPricetagOutline size={20} />,
  },
  {
    name: "Manage Settings",
    url: "/manage-settings",
    icon: <IoSettingsOutline size={20} />,
  },
  {
    name: "Email Templates",
    url: "/email-templates",
    icon: <CiMail size={20} />,
  },
];

export const InvestorLink = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "My Ideas",
    url: "/manage-my-ideas",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "Manage Interests",
    url: "/manage-interests",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "My Categories",
    url: "/manage-my-categories",
    icon: <HiOutlineLightBulb size={20} />,
  },
];
