import React, { useState } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../../store/authStore';
import { useMutation, useQuery } from 'react-query';
import ModalContainer from '../../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../../CustomUIElements/Inputs/Inputs';
import { toast } from 'react-toastify';
import { fetchSubscriptions } from '../../../utils/fetchSubscriptions';
import { addSubscription } from '../../../utils/addSubscription';
import ReactQuill from "react-quill";
import parse from "html-react-parser"
import { HiOutlineSearch } from 'react-icons/hi';
import { fetchViewableIdeas } from '../../../utils/fetchViewableIdeas';
import InterestTable from './InterestTable';

function InterestHome() {
    const [openModal, setOpenModal] = useState(false);
    const [formValues, setFormValues] = useState({});
    const { removeUser } = useAuthStore()
    // const [isLoading, setIsLoading] = useState(false);
    const [interests, setInterests] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [desc, setDesc] = useState("")
    const [searchQuery, setSearchQuery] = useState("");

    const { isLoading: loadingFunnels, isRefetching, refetch } = useQuery("fetchInterests", fetchViewableIdeas, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setInterests(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })


    return (
        <div className='min-h-[85vh]'>
            <div className="flex justify-between items-center  mb-[2rem]">
                <div className="flex items-center gap-4">
                    <h1 className="text-[20px] font-semibold">Manage Interests</h1>
                    <div className="relative">
                        <input
                            className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <span className="absolute top-[14px] left-[15px] text-[20px]">
                            <HiOutlineSearch />
                        </span>
                    </div>
                </div>
                {/* <div className="w-fit">
                    <Buttons
                        type="button"
                        text="Add New Subscription"
                        variant="primary"
                        handleClick={() => setOpenModal(true)}
                    />
                </div> */}
            </div>
            {selectedRows?.length > 0 &&
                <div className="flex gap-2 items-center">
                    <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
                    <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]">Enable</button>
                </div>
            }
            <div className="mt-4 mb-8">
                <InterestTable data={interests} loading={loadingFunnels || isRefetching} refetch={refetch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
            </div>
            {openModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem]  mb-4">
                            <h3 className="">Add New Subscription</h3>
                            <LiaTimesSolid
                                onClick={() => { setOpenModal(false); setFormValues({}) }}
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                </ModalContainer>
            )}
        </div>

    )
}

export default InterestHome