import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const authStore = (set) => ({
  userProfile: null,
  saveUserProfile: (user) => set({ userProfile: user }),
  removeUser: () => set({ userProfile: null }),
  userNotifications: [],
  saveUserNotifications: (userNotifications) => set({ userNotifications }),
});

const useAuthStore = create(
  persist(devtools(authStore), {
    name: "userProfile",
  })
);

export default useAuthStore;
