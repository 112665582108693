import React, { useEffect, useState } from 'react'
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { makePayment } from '../utils/makePayment';
import { toast } from 'react-toastify';
import { fetchSubscriptions } from '../utils/fetchSubscriptions';
import parse from "html-react-parser"

function PlansPage() {
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchSubscriptions().then((res) => {
            setSubscriptionPlans(res?.data?.data);
        })
    }, [])

    const makePaymentHandler = async (id) => {
        setLoading(true)
        const token = window.localStorage.getItem("token");
        if (!token) {
            setLoading(false)
            toast.warn("Please login to continue")
            window.location.href = "/login?return_to=/plans"
        } else {
            setLoading(false)
            await makePayment(id).then((res) => {
                window.location.href = res?.data
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    window.location.href = "/login?return_to=/plans"
                }
            })
        }
    }
    return (
        <section className="app-container">
            <div className="my-[4.6rem] max-w-[102rem] mx-auto flex flex-col justify-center">
                <div className="text-center mb-[4.6rem]">
                    <p className="bg-[#EDF4FC] text-[#008FD2] px-[1.2rem] py-[0.4rem] rounded-[1.5rem] font-bold text-center text-[1.8rem] mx-auto w-max mb-[2.6rem]">
                        Our Subscription Plans
                    </p>
                    <p className='text-[1.5rem] text-slate-500'>Choose plan that suits best for your business needs, Our plans scales with you based on your needs</p>
                </div>
                <div className='grid grid-cols-2 gap-12'>
                    {subscriptionPlans?.map((plans, index) => {
                        return (
                            <div key={index} className='w-full rounded-[.8rem] border'>
                                <div className=' w-full h-8 rounded-t-[.8rem]' style={{ background: plans?.colourCode }} />
                                <div className='bg-white p-12'>
                                    <div className='flex justify-between items-center mb-8'>
                                        <p className='font-bold text-[1.9rem]'>{plans?.topic}</p>
                                        {/* <span className='bg-green-200 text-green-700 px-2 py-1 rounded-md'>
                                            <p className='text-[1.2rem]'>For Individals</p>
                                        </span> */}
                                    </div>
                                    <div>
                                        <span className='flex gap-3 items-end'>
                                            <p className='font-bold text-[3rem]'>£{plans?.price}</p>
                                            <p className='text-[1.4rem] text-slate-500'>/ Per Month</p>
                                        </span>
                                        <div className='text-[1.5rem] mt-8 mb-4 text-slate-500'>
                                            {parse(plans?.description)}
                                        </div>
                                        <button className='w-full text-[1.6rem] py-3 rounded-xl bg-slate-200 hover:opacity-50' onClick={() => makePaymentHandler(plans?.id)}
                                            disabled={loading}>{loading ? "Please wait..." : "Choose Plan"}</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {/* <div className='w-full rounded-[.8rem] border'>
                        <div className='bg-blue-200 w-full h-8 rounded-t-[.8rem]' />
                        <div className='bg-white p-12'>
                            <div className='flex justify-between items-center mb-8'>
                                <p className='font-bold text-[1.9rem]'>Bronze</p>
                                <span className='bg-blue-200 text-blue-700 px-2 py-1 rounded-md'>
                                    <p className='text-[1.2rem]'>Bronze</p>
                                </span>
                            </div>
                            <div>
                                <span className='flex gap-3 items-end'>
                                    <p className='font-bold text-[3rem]'>£0</p>
                                    <p className='text-[1.4rem] text-slate-500'>/ Per Month</p>
                                </span>
                                <p className='text-[1.5rem] mt-8 mb-4 text-slate-500'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .
                                </p>
                                <p className='underline text-[1.3rem]'>Billed Monthly</p>
                                <ul className='my-8 space-y-8'>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                </ul>
                                <button className='w-full text-[1.6rem] py-3 rounded-xl bg-slate-200 hover:opacity-50'>Choose Plan</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full rounded-[.8rem] border'>
                        <div className='bg-blue-200 w-full h-8 rounded-t-[.8rem]' />
                        <div className='bg-white p-12'>
                            <div className='flex justify-between items-center mb-8'>
                                <p className='font-bold text-[1.9rem]'>Gold</p>
                                <span className='bg-blue-200 text-blue-700 px-2 py-1 rounded-md'>
                                    <p className='text-[1.2rem]'>Gold</p>
                                </span>
                            </div>
                            <div>
                                <span className='flex gap-3 items-end'>
                                    <p className='font-bold text-[3rem]'>£0</p>
                                    <p className='text-[1.4rem] text-slate-500'>/ Per Month</p>
                                </span>
                                <p className='text-[1.5rem] mt-8 mb-4 text-slate-500'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .
                                </p>
                                <p className='underline text-[1.3rem]'>Billed Monthly</p>
                                <ul className='my-8 space-y-8'>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                </ul>
                                <button className='w-full text-[1.6rem] py-3 rounded-xl bg-slate-200 hover:opacity-50'>Choose Plan</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full rounded-[.8rem] border'>
                        <div className='bg-blue-200 w-full h-8 rounded-t-[.8rem]' />
                        <div className='bg-white p-12'>
                            <div className='flex justify-between items-center mb-8'>
                                <p className='font-bold text-[1.9rem]'>Investor</p>
                                <span className='bg-blue-200 text-blue-700 px-2 py-1 rounded-md'>
                                    <p className='text-[1.2rem]'>Investor</p>
                                </span>
                            </div>
                            <div>
                                <span className='flex gap-3 items-end'>
                                    <p className='font-bold text-[3rem]'>£0</p>
                                    <p className='text-[1.4rem] text-slate-500'>/ Per Month</p>
                                </span>
                                <p className='text-[1.5rem] mt-8 mb-4 text-slate-500'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .
                                </p>
                                <p className='underline text-[1.3rem]'>Billed Monthly</p>
                                <ul className='my-8 space-y-8'>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                </ul>
                                <button className='w-full text-[1.6rem] py-3 rounded-xl bg-slate-200 hover:opacity-50'>Choose Plan</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full rounded-[.8rem] border'>
                        <div className='bg-blue-200 w-full h-8 rounded-t-[.8rem]' />
                        <div className='bg-white p-12'>
                            <div className='flex justify-between items-center mb-8'>
                                <p className='font-bold text-[1.9rem]'>University</p>
                                <span className='bg-blue-200 text-blue-700 px-2 py-1 rounded-md'>
                                    <p className='text-[1.2rem]'>University</p>
                                </span>
                            </div>
                            <div>
                                <span className='flex gap-3 items-end'>
                                    <p className='font-bold text-[3rem]'>£0</p>
                                    <p className='text-[1.4rem] text-slate-500'>/ Per Month</p>
                                </span>
                                <p className='text-[1.5rem] mt-8 mb-4 text-slate-500'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .
                                </p>
                                <p className='underline text-[1.3rem]'>Billed Monthly</p>
                                <ul className='my-8 space-y-8'>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                    <li className='flex items-center gap-4 text-[1.6rem]'>
                                        <span>
                                            <IoMdCheckmarkCircleOutline className='text-green-400' />
                                        </span>
                                        <p className='text-[1.5rem]'>2 Free Websites</p>
                                    </li>
                                </ul>
                                <button className='w-full text-[1.6rem] py-3 rounded-xl bg-slate-200 hover:opacity-50'>Choose Plan</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default PlansPage