// import { header } from "../libs/authHeader";
import apiClient from "../libs/http-common";
export const activateEntityStatus = async (entityName, id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.put(`/${entityName}/activate`, {id}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
