import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchSingleIdea } from "../../../utils/fetchSingleIdea";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import useIdeaStore from "../../../store/ideaStore";
import NotesSection from "./Notes/NotesSection";
import useAuthStore from "../../../store/authStore";
import ShareSection from "./Share/ShareSection";
import QuestionSection from "./Questions/QuestionSection";
import { fetchMyNotes } from "../../../utils/fetchMyNotes";
import { fetchQuestions } from "../../../utils/fetchQuestions";
import Scale from "../../../CustomUIElements/Loaders/ScaleLoader";

function SingleIdea() {
  const { id } = useParams();
  const { removeUser } = useAuthStore();
  const [singleIdea, setSingleIdea] = useState(null);
  const [notes, setNotes] = useState(null);
  const [questions, setQuestions] = useState(null);
  const navigate = useNavigate();
  const { isError, isLoading, isFetching, refetch } = useQuery(
    "fetchSingleIdea",
    () => fetchSingleIdea({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setSingleIdea(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );
  const { refetch: fetchNotes } = useQuery(
    "fetchMyNotes",
    () => fetchMyNotes({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setNotes(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );
  const { refetch: fetchIdeaQuestions } = useQuery(
    "fetchQuestions",
    () => fetchQuestions({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setQuestions(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );
  useEffect(() => {
    if (isError) navigate("/login");
  });
  useEffect(() => {
    refetch();
    fetchNotes();
    fetchIdeaQuestions();
  }, [id]);

  return (
    <div className="!my-[4.4rem] app-container">
      {isLoading || isFetching ? (
        <div className="flex justify-center items-center h-[60dvh]">
          <Scale />
        </div>
      ) : (
        <div className="w-[82rem] mx-auto">
          <Header categoryId={singleIdea?.categoryId} singleIdea={singleIdea} />
          <div className="flex mt-[2.4rem] pb-[2.4rem] border-b items-start justify-between">
            <NotesSection notes={notes} setNotes={setNotes} />
            <ShareSection />
          </div>
          <div className="mt-[2.4rem]">
            <QuestionSection
              questions={questions}
              setQuestions={setQuestions}
              singleIdea={singleIdea}
              setSingleIdea={setSingleIdea}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleIdea;
