import React from "react";
import MyCategoriesHome from "../../Components/AdminDashboard/myCategories/Home";

function ManageMyCategories() {
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1]">
            <MyCategoriesHome />
        </div>
    );
}

export default ManageMyCategories;
