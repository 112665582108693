import React from "react";

function NavbarPopup({children}) {
  return (
    <div className="w-[31rem] rounded-xl absolute right-0 bg-white top-20 shadow-lg border border-[#E4E4E4]">
    {children}
    </div>
  );
}

export default NavbarPopup;
